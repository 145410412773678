import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import CodeContainer from "../code";

const useStyles = makeStyles(() => {
	return {
		keyOrUrl: {
			color: "#b2cc98"
		},
		headerKey: { color: "#569cd6" },
		value: { color: "#49b5a1" }
	};
});

export const ApiRequestExample = () => {
	const classes = useStyles();
	return (
		<CodeContainer>
			<span>POST</span> <span className={classes.keyOrUrl}>/acme/prod</span>
			<br />
			<span className={classes.headerKey}>Host:</span>{" "}
			<span className={classes.value}>api.runly.io</span>
			<br />
			<span className={classes.headerKey}>Content-Type:</span>{" "}
			<span className={classes.value}>application/json</span>
			<br />
			<br />
			<span>{"{"}</span>
			<br />
			<span className={classes.keyOrUrl}>{"\t"}"job"</span>
			<span>:</span> <span className={classes.value}>"PlaceMapper"</span>
			<br />
			<span className={classes.keyOrUrl}>{"\t"}"unmappedOnly"</span>
			<span>:</span> <span className={classes.value}>true</span>
			<br />
			<span>{"}"}</span>
			<br />
			<br />
			<span>201 Created</span>
		</CodeContainer>
	);
};

export const PublishExample = () => {
	const classes = useStyles();

	return (
		<CodeContainer>
			<span>{"$"} dotnet publish Acme.MyJob.1.0.0.nupkg</span>
			<br />
			<span className={classes.keyOrUrl}>
				{"\u00a0\u00a0"}Published Acme.MyJob.1.0.0.nupkg to runly.io/acme
			</span>

			<br />
			<br />
			<span>{"$"} npm publish</span>
			<br />
			<span className={classes.keyOrUrl}>
				{"\u00a0\u00a0"}Published @acme/myjob 1.0.0 to runly.io/acme
			</span>
		</CodeContainer>
	);
};
