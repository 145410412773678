import React from "react";
import { graphql } from "gatsby";

import { Box, Container } from "@material-ui/core";

import Layout from "../layout";
import Seo from "../seo";

import Headline from "../hero/headline";

import PlatformFeatures from "../landing/platform";

import CtaSection from "../cta";
import GetStarted from "../landing/get-started";

const PlatformPage = ({ location, data: { logo } }) => (
	<Layout location={location} disableFooterMargin maxWidth="lg">
		<Seo
			title="Automate the deployment and monitoring of your background jobs"
			description="Focus on the code you care about. Let Runly handle the plumbing of deploying, executing, scaling, and monitoring your jobs in production."
			image={logo.cover.img.src}
		/>

		<Box clone pt={4} pb={20}>
			<Container maxWidth="lg">
				<Headline title="Turbocharge your background jobs">
					Focus on the code you care about. Let Runly handle the plumbing of{" "}
					<strong>deploying</strong>, <strong>executing</strong>,{" "}
					<strong>scaling</strong>, and <strong>monitoring</strong> your jobs in
					production.
				</Headline>
			</Container>
		</Box>

		<PlatformFeatures />

		<CtaSection py={20}>
			<GetStarted mt={8} />
		</CtaSection>
	</Layout>
);

export default PlatformPage;

export const platformQuery = graphql`
	query PlatformQuery {
		logo: file(sourceInstanceName: { eq: "logos" }, name: { eq: "cover" }) {
			cover: childImageSharp {
				img: fixed(width: 800) {
					src
				}
			}
		}
	}
`;
