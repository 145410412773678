import React from "react";

import { Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const HomeCode = ({ className, children }) => {
	const classes = usesHomeCodeStyles();
	return (
		<div className={`${classes.root} ${className || ""}`}>
			<Box clone className={classes.border} borderRadius={16} p={1}>
				<Paper elevation={4}>
					<Box
						component="pre"
						className={classes.content}
						borderRadius={12}
						p={3}
						m={0}
					>
						{children}
					</Box>
				</Paper>
			</Box>
		</div>
	);
};

const usesHomeCodeStyles = makeStyles(theme => {
	return {
		root: {
			textAlign: "center"
		},
		border: {
			textAlign: "left",
			display: "inline-block",
			background: "#ccc",
			maxWidth: "100%"
		},
		content: {
			tabSize: 4,
			fontSize: theme.typography.body2.fontSize,
			fontFamily: "'Fira Code', Consolas, monospace",
			whiteSpace: "pre",
			background: "#29333d",
			color: "#ededed",
			maxWidth: "100%",
			overflowX: "auto"
		}
	};
});

export default HomeCode;
