import React from "react";

import { Container } from "@material-ui/core";

import { ApiRequestExample, PublishExample } from "./code";
import { ReactComponent as CalendarArt } from "./calendar.svg";
import NodesScreenshot from "./nodes.png";
import TimelineScreenshot from "./timeline.png";

import CtaSection from "../../cta";
import Feature from "../../feature-row";

const Beyond = () => (
	<>
		<FeatureRow
			backgroundColor="paper"
			title="Fast and easy deployments"
			art={<PublishExample />}
			sm={6}
			md={6}
		>
			Deploy jobs as NuGet/npm packages using tools you already know and love.
			Runly takes care of automatically deploying your job packages to all of
			your worker nodes. Never bring your background apps down for maintenance.
		</FeatureRow>

		<FeatureRow
			title="Automate your jobs"
			art={<ApiRequestExample />}
			sm={6}
			md={6}
			reverse
		>
			Use familiar REST concepts in Runly's JSON API to manage your background
			jobs. Run fire-and-forget jobs, delayed jobs, recurring jobs, batch
			processing, workflows and more.
		</FeatureRow>

		<FeatureRow
			backgroundColor="paper"
			title="Never miss a beat"
			art={<CalendarArt />}
		>
			Use CRON expressions for fine-grained control of job scheduling. Stay in
			the know with optional notifications of job results for you and your
			users.
		</FeatureRow>

		<FeatureRow
			title="Shine a light on your background jobs"
			art={<img src={TimelineScreenshot} alt="Job Timeline" />}
			sm={6}
			md={6}
			reverse
		>
			Don't let job failures and poor performance hide in the background. Runly
			surfaces useful metrics and debug info in one place to help you understand
			how your system is performing and impacting your users.
		</FeatureRow>

		<FeatureRow
			backgroundColor="paper"
			title="Bare metal or in the cloud"
			art={<img src={NodesScreenshot} alt="Job Timeline" />}
			sm={6}
			md={6}
		>
			Run your code where you want — on your server or the cloud. Better yet,
			run it on both. Runly manages your environments and nodes, putting you in
			control of where code runs.
		</FeatureRow>
	</>
);

const FeatureRow = ({ backgroundColor, ...props }) => (
	<CtaSection pt={25} pb={10} backgroundColor={backgroundColor}>
		<Container maxWidth="md">
			<Feature {...props} />
		</Container>
	</CtaSection>
);

export default Beyond;
